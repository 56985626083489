import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function LogoutPage() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true)
  React.useEffect(() => {
    localStorage.clear();

    setTimeout(() => {
      navigate("/", { replace: true });
      setOpen(false)
    }, 2000);
  }, [navigate]);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
