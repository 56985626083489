import React from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Page from "./Page";
import QrReader from "react-qr-reader";
import { gql, useMutation, useQuery } from "@apollo/client";
import beep1 from "../Assets/beep-08b.mp3";
import useSound from "use-sound";
import logo from "../Assets/eventegration.png";
import "./style.css";
import { IMAGES_API_URL } from "../constants";
import dummyLogo from "../Assets/dummy_logo.png";
import dummySponsor from "../Assets/dummy_sponsor.jpeg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Margin } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import hazardImg from "../Assets/qr-code.png";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const isMobileDevice = () => {
  return /Mobi|Android/i.test(window.navigator.userAgent);
};

export default function ScanningVisitorsPage() {
  const { data, error, loading } = useQuery(GET_MY_AUTH);
  const [showImage, setShowImage] = React.useState(true);
  const [showImage2, setShowImage2] = React.useState(true);

  const img =
    data?.eventId === "7493635113"
      ? dummyLogo
      : `${IMAGES_API_URL}logo/${data?.eventId}.png`;
  const img2 =
    data?.eventId === "7493635113"
      ? dummySponsor
      : `${IMAGES_API_URL}sponsor/${data?.eventId}.jpg`;
  // console.log(data);
  const handleImageError = (type) => {
    if (type === "logo") setShowImage(false);
    if (type === "sponsor") setShowImage2(false);
  };
  console.log(isMobileDevice());
  if (!isMobileDevice()) {
    return (
      <Page title="Badge Scanner">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          sx={{ backgroundColor: "#121212" }}
        >
          <Typography
            variant="h4"
            align="center"
            sx={{ mt: 4, mb: 2, color: "#ffffff" }}
          >
            Mobile Access Only
          </Typography>
          <img
            src={hazardImg}
            alt="Hazard"
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "4px",
              marginBottom: "16px",
            }}
          />
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 4, color: "#aaaaaa" }}
          >
            Please open this page on a mobile device to scan the QR of badge.
          </Typography>
          <Box
            sx={{
              backgroundColor: "#333333",
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Scan the QR code above and open the portal on your mobile phone to
              access this QR scanner for a seamless experience.{" "}
            </Typography>
          </Box>
        </Box>
      </Page>
    );
  }
  return (
    <Page title="Badge Scanner" sx={{ Margin: 0 }}>
      <>
        <Stack
          direction={"column"}
          justifyContent="space-evenly"
          margin={0}
          spacing={0}
          sx={{ maxHeight: "90vh" }}
        >
          <Stack>
            {data?.eventId && showImage ? (
              <img
                src={img}
                style={{
                  width: "100%",
                  height: "80px",
                  objectFit: "contain",
                }}
                alt="Event Logo"
                onError={() => handleImageError("logo")}
              />
            ) : (
              <Typography variant="h4" gutterBottom align="center">
                Badge QR Scanner
              </Typography>
            )}
          </Stack>
          {loading ? (
            <LinearProgress />
          ) : !!error ? (
            <Typography color="error">
              {JSON.stringify(error, null, 2)}
            </Typography>
          ) : data?.eventId && data?.standId ? (
            <ScanAndUpload eventId={data.eventId} standId={data.standId} />
          ) : (
            ""
          )}
          <Box width={"100%"}>
            {data?.eventId && showImage2 && (
              <img
                src={img2}
                style={{
                  width: "100%",
                  height: "160px",
                  objectFit: "contain",
                }}
                alt="Sponsor"
                onError={() => handleImageError("sponsor")}
              />
            )}
          </Box>
          <Box px={4} mt={0} sx={{ textAlign: "center" }}>
            <Typography align="center" fontSize={10} gutterBottom>
              Powered by...
            </Typography>
            <img src={logo} width="60%" alt="Logo" />
          </Box>
        </Stack>
      </>
    </Page>
  );
}

function ScanAndUpload({ eventId }) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [playBeepSuccess] = useSound(beep1);
  const [playBeepFailed] = useSound(beep1, { playbackRate: 0.4 });
  const [showCommentPopup, setShowCommentPopup] = React.useState(false);
  const [showButton, setShowButton] = React.useState(false);
  // const [exitWithoutComment, setExitWithoutComment] = React.useState(false); // Flag to track exit without comment
  const [commentData, setCommentData] = React.useState(null);
  const [runQuery, setRunQuery] = React.useState(false);
  const [permissionDenied, setPermissionDenied] = React.useState(false); // State to track permission denial
  const [leadType, setLeadType] = React.useState(""); // State to track permission denial
  console.log("permission", permissionDenied);

  const handleDuplicateCheck = async (role, id) => {
    console.log(role, id);
    const m = await checkId({
      variables: { role: role, id: id },
    });
    const isDuplicate = m?.data?.result;
    console.log(isDuplicate, ">>>>>>");
    return isDuplicate;
  };
  const handleGoBack = () => {
    console.log("run Back");
    setLeadType("");
    setShowCommentPopup(false);
    setShowButton(true);
  };
  const handleScan = async (d) => {
    // setMessage(d.eventId)
    // if (!loading) { return; }

    // Parse `eventId`, `role`, and `id` if `d` contains vCard data
    let neweventId = d.eventId;
    let role = d.role;
    let id = d.id;

    if (typeof neweventId === "string" && neweventId.includes("BEGIN:VCARD")) {
      // Extract the eventId, role, and id from the vCard format
      const eventIdMatch = neweventId.match(/NOTE:UC:(\d+)/);
      const idMatch = id.match(/(\d+)/);

      if (eventIdMatch) neweventId = eventIdMatch[1];
      if (idMatch) id = idMatch[1];
    }
    console.log(neweventId, role, id);

    const isDuplicate = await handleDuplicateCheck(role, id);
    const isSameEventId = neweventId === eventId;

    // alert(eventId)

    if (isDuplicate === "true" || !isSameEventId) {
      setMessage(!isSameEventId ? "Invalid QR" : "Already scanned & uploaded");
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    } else {
      if (!loading) {
        setData({ eventId:neweventId,role,id, timeout: Math.floor(new Date().getSeconds() / 5) });
      }
      setLoading(true);
      setShowButton(true);
    }
  };

  React.useEffect(() => {
    // if (loading && !showButton) {
    //   setShowButton(true);
    // }
    if (commentData) {
      setRunQuery(true);
      setShowCommentPopup(false);
    } else {
      return;
    }
  }, [commentData]);

  const handleExit = async () => {
    setRunQuery(true);
    setShowButton(false);
    setShowCommentPopup(false);
    // setExitWithoutComment(true); // Set flag indicating exit without comment
  };
  const handleAddComment = (e) => {
    setLeadType(e);
    // setShowButton(false)
    setShowCommentPopup(true); // Show the comment popup
  };

  const handleError = (e) => {
    setError(e.message);
    console.log("error run", e.message);
    if (e.message === "Permission denied") {
      setMessage(null);
      setPermissionDenied(true);
      setShowCommentPopup(false);
      setShowButton(false);
      setCommentData(null);
      setRunQuery(false);
      setLeadType("");
    }
    setMessage(null);
    setShowCommentPopup(false);
    setShowButton(false);
    setCommentData(null);
    setRunQuery(false);
    setLeadType("");
  };
  const handleClosePopup = () => {
    // Show confirmation dialog
    const confirmClose = window.confirm(
      "Are you sure you want to leave without saving this lead?"
    );

    // If user confirms, close the popup
    if (confirmClose) {
      setShowCommentPopup(false);
      setLoading(false);
      setRunQuery(false);
      setMessage(null);
      setCommentData(null);
      setShowButton(false);
      setError(null);
    }
  };

  const [uploadData] = useMutation(UPLOAD_SCAN_RESULT, {
    onError: handleError,
  });
  const [checkId] = useMutation(CHECK_ID, {
    onError: handleError,
  });

  React.useEffect(() => {
    if (!runQuery) {
      return;
    }
    if (!data?.eventId || !data?.role || !data?.id) {
      playBeepSuccess();
      playBeepFailed();
      return;
    }
    if (data.eventId !== eventId) {
      playBeepFailed();
      return setError("Invalid Event QR");
    }
    if (showButton) {
      setShowButton(false);
    }
    if (showCommentPopup) {
      setShowCommentPopup(false);
    }
    const dummyinp = { type: "", remark: "" };
    let t;
    (async () => {
      setLoading(true);
      setError(null);
      setMessage(null);
      const variables = !commentData
        ? {
            role: data.role,
            id: data.id,
            inp: JSON.stringify(dummyinp),
          }
        : {
            role: data.role,
            id: data.id,
            inp: JSON.stringify(commentData),
          };

      const m = await uploadData({
        variables: variables,
      });
      // console.log(m.data.result, loading, showButton);
      setMessage(m.data.result || "Something went wrong!");
      setCommentData(null);
      setLoading(false);
      await new Promise((resolve) => {
        // Only resolve the promise if the comment popup is not shown
        if (!showCommentPopup) {
          t = setTimeout(resolve, 2000);
        }
      });
      setMessage(null);
      console.log("object");

      if (runQuery) {
        setRunQuery(false); // Reset flag after uploading data
      }
    })();

    return () => {
      clearTimeout(t);
    };
  }, [runQuery]);

  const handleBrowserInstructions = (browser) => {
    switch (browser) {
      case "Google Chrome":
        return (
          <span>
            Click on the lock icon in the address bar and select "Site
            settings". Under "Permissions", change the camera access to "Allow".
          </span>
        );
      case "Microsoft Edge":
        return (
          <span>
            Click on the lock icon in the address bar and select "Permissions".
            Change the camera access to "Allow".
          </span>
        );
      case "Apple Safari":
        return (
          <span>
            Go to Safari Preferences {">"} Websites {">"} Camera. Select the
            website and choose "Allow" from the dropdown.
          </span>
        );
      case "Mozilla Firefox":
        return (
          <span>
            Click on the lock icon in the address bar and select "More
            Information". Under "Permissions", change the camera access to
            "Allow".
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Stack spacing={2} sx={{ position: "relative" }}>
        <Scanner
          onScan={handleScan}
          onError={handleError}
          showButton={showButton}
          error={error}
          message={message}
        />
        {/* {permissionDenied && (
      <div style = {{zIndex:50}}>
        <p>Camera access permission denied. Please allow access to your camera to continue.</p>
        <button onClick={handleRequestPermission}>Allow Camera Access</button>
      </div>
    )} */}
        {/* {permissionDenied && (
            )} */}
        {showButton && (
          <Box
            sx={{
              position: "absolute",
              bottom: "50%",
              transform: "translateY(50%)", // Move content up by half of its own height
              left: 0,
              right: 0,
              zIndex: 10,
              backdropFilter: "blur(20px)",
              borderRadius: "10px",
              backgroundColor: "transparent",
              boxShadow: "0px -4px 6px rgba(0, 0, 0, 0.1)",
              padding: "36px",
              minHeight: "100%",
            }}
            showLabels
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "15px",
                right: "15px",
                backgroundColor: "red",
                color: "white",
              }}
              onClick={handleClosePopup}
            >
              <CloseIcon />
            </IconButton>
            <Stack
              direction="column"
              marginTop={5}
              justifyContent="space-between"
              alignItems="stretch"
              minHeight={"100%"}
              spacing={2}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  fontSize: "1.1rem",
                  borderRadius: "7px",
                  paddingY: "25px",
                  animation: "shine 2s infinite",
                }}
                onClick={() => handleAddComment("Hot Lead")}
              >
                Hot Lead
              </Button>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  fontSize: "1.1rem",
                  borderRadius: "7px",
                  paddingY: "25px",
                  animation: "shine 2s infinite",
                }}
                onClick={() => handleAddComment("Warm Lead")}
              >
                Warm Lead
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{
                  fontSize: "1.1rem",
                  borderRadius: "7px",
                  paddingY: "25px",
                  animation: "shine 2s infinite",
                }}
                onClick={() => handleAddComment("Cold Lead")}
              >
                Cold Lead
              </Button>
            </Stack>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: 5,
          }}
        >
          {loading ? (
            <>
              <LinearProgress />
            </>
          ) : permissionDenied ? (
            <>
              <Paper
                sx={{ display: "flex", flexDirection: "column", zIndex: 54 }}
                variant="elevation"
                padding={2}
              >
                <Typography variant="body1">
                  Camera access permission denied. Please allow access to your
                  camera to continue.
                </Typography>
                <Typography variant="body1">
                  Follow the steps below based on your browser:
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={<strong>Google Chrome:</strong>}
                      secondary={handleBrowserInstructions("Google Chrome")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={<strong>Microsoft Edge:</strong>}
                      secondary={handleBrowserInstructions("Microsoft Edge")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={<strong>Apple Safari:</strong>}
                      secondary={handleBrowserInstructions("Apple Safari")}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={<strong>Mozilla Firefox:</strong>}
                      secondary={handleBrowserInstructions("Mozilla Firefox")}
                    />
                  </ListItem>
                </List>
              </Paper>
            </>
          ) : error ? (
            <Stack spacing={3}>
              <Chip
                color={
                  ["approved", "granted", "success", "allowed"].some((x) =>
                    error.toLowerCase().includes(x)
                  )
                    ? "success"
                    : "error"
                }
                label={error}
                size="large"
                icon={
                  ["approved", "granted", "success", "allowed"].some((x) =>
                    error.toLowerCase().includes(x)
                  ) ? (
                    <CheckCircleOutlineIcon sx={{ color: "green" }} />
                  ) : (
                    <NotInterestedIcon sx={{ color: "red" }} />
                  )
                }
                sx={{ fontSize: 20, p: 3 }}
              />
              <Typography variant="h4" align="center">
                {data?.id}
              </Typography>
            </Stack>
          ) : message ? (
            <>
              <Stack spacing={3}>
                <Chip
                  color={
                    ["approved", "granted", "success", "allowed"].some((x) =>
                      message.toLowerCase().includes(x)
                    )
                      ? "success"
                      : "error"
                  }
                  label={message}
                  size="large"
                  icon={
                    ["approved", "granted", "success", "allowed"].some((x) =>
                      message.toLowerCase().includes(x)
                    ) ? (
                      <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    ) : (
                      <NotInterestedIcon sx={{ color: "red" }} />
                    )
                  }
                  sx={{ fontSize: 20, p: 3 }}
                />
                <Typography variant="h4" align="center">
                  {data?.id}
                </Typography>
              </Stack>
            </>
          ) : (
            ""
          )}
        </Box>
      </Stack>
      {showCommentPopup && (
        <CommentPopup
          open={showCommentPopup}
          onClose={handleClosePopup}
          back={handleGoBack}
          leadType={leadType}
          setCommentData={setCommentData}
        />
      )}
    </>
  );
}
function Scanner({ onScan, onError, message, error, showButton }) {
  const handleResult = async (result, error) => {
    try {
      if (!!result) {
        const t = result;
        // console.log(t);
        if (!t) {
          throw error || new Error("Invalid QR");
        }
        const [eventId, role, id] = t.split(",");
        if (!eventId || !role || !id) {
          throw new Error("Invalid QR");
        }
        await onScan({ eventId, role, id });
      } else {
        console.log("Not Found");
      }
    } catch (e) {
      console.log(e.message);
      onError(e);
    }
  };
  return (
    <>
      <div className="scanner-container">
        {message === null && error === null && showButton === false && (
          <div className="scanner-animation" />
        )}
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          delay={1500}
          onScan={handleResult}
          onError={onError}
          showViewFinder={false}
          style={{
            color: "white",
            zIndex: 100,
            boxSizing: "border-box",
            border: "5px solid rgba(0, 0, 0, 0.3)",
            width: "100%",
            height: "100%",
            padding: "5px",
            "& video": { padding: "5px" },
            "& div": {
              boxShadow: "rgb(13 111 220) 0px 0px 0px 5px inset",
            },
            background:
              "linear-gradient(to right, white 4px, transparent 4px) 0 0," +
              "linear-gradient(to right, white 4px, transparent 4px) 0 100%," +
              "linear-gradient(to left, white 4px, transparent 4px) 100% 0," +
              "linear-gradient(to left, white 4px, transparent 4px) 100% 100%," +
              "linear-gradient(to bottom, white 4px, transparent 4px) 0 0," +
              "linear-gradient(to bottom, white 4px, transparent 4px) 100% 0," +
              "linear-gradient(to top, white 4px, transparent 4px) 0 100%," +
              "linear-gradient(to top, white 4px, transparent 4px) 100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "20px 20px",
          }}
        />
      </div>
    </>
  );
}

function CommentPopup({ open, onClose, setCommentData, leadType, back }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      remark: "",
    },
  });

  const onSubmit = (data) => {
    // Concatenate the "Other" value with the custom lead type if selected
    // const newType = data.type === "Other" ? `${data.type}: ${data.other}` : data.type;
    // Create new data object with concatenated type and remark
    const newData = { type: leadType, remark: data.remark };
    // Handle form submission
    setCommentData(newData);
  };

  const handleClose = (event, reason) => {
    // Prevent closing when clicking outside the dialog content
    if (reason !== "backdropClick") {
      // Ask for confirmation before closing
      const isSure = window.confirm("Are you sure you want to exit?");
      if (isSure) {
        onClose();
      }
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        minHeight="100%"
        fullWidth
        sx={{ zIndex: 999 }}
      >
        {/* <DialogTitle>Add Comment</DialogTitle> */}
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction={"column"}
              minHeight={"100%"}
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              padding={2}
            >
              {/* <Controller
              control={control}
              name="type"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.type}>
                  <InputLabel id="type-label">Lead Type</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    {...field}
                  >
                    <MenuItem value="Hot Lead">Hot Lead</MenuItem>
                    <MenuItem value="Mild Lead">Mild Lead</MenuItem>
                    <MenuItem value="Exhibition Attendee">Exhibition Attendee</MenuItem>
                    <MenuItem value="Business Card Exchange">Business Card Exchange</MenuItem>
                    <MenuItem value="Product Demo Request">Product Demo Request</MenuItem>
                    <MenuItem value="Sales Inquiry">Sales Inquiry</MenuItem>
                    <MenuItem value="Partnership Inquiry">Partnership Inquiry</MenuItem>
                    <MenuItem value="Service Inquiry">Service Inquiry</MenuItem>
                    <MenuItem value="Feedback Submission">Feedback Submission</MenuItem>
                    <MenuItem value="Subscription Request">Subscription Request</MenuItem>
                    <MenuItem value="Catalog Request">Catalog Request</MenuItem>
                    <MenuItem value="Brochure Request">Brochure Request</MenuItem>
                    <MenuItem value="Contact Form Submission">Contact Form Submission</MenuItem>
                    <MenuItem value="Networking Opportunity">Networking Opportunity</MenuItem>
                    <MenuItem value="Referral">Referral</MenuItem>
                    <MenuItem value="Follow-up Request">Follow-up Request</MenuItem>
                    <MenuItem value="Appointment Request">Appointment Request</MenuItem>
                    <MenuItem value="Lead Qualification">Lead Qualification</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {field.value === 'Other' && (
                    <TextField
                      id="other"
                      label="Other Lead Type"
                      variant="outlined"
                      fullWidth
                      {...register("other")}
                      sx={{ marginY: 2 }}
                    />
                  )}
                  {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
                </FormControl>
              )}
            /> */}
              {/* <Typography variant="body1">Meeting Summary is not madnatory</Typography> */}

              <Controller
                control={control}
                name="remark"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Meeting Summary (If Any)"
                    variant="outlined"
                    placeholder=""
                    fullWidth
                    multiline
                    rows={15}
                    error={!!errors.remark}
                    helperText={errors.remark && errors.remark.message}
                  />
                )}
              />
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Button type="submit" variant="contained" color="success">
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => back()}
                  color="warning"
                >
                  Back
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export const GET_MY_AUTH = gql`
  query {
    eventId
    standId
    company
  }
`;

const UPLOAD_SCAN_RESULT = gql`
  mutation ($role: String!, $id: String!, $inp: String) {
    result: uploadScanResult(role: $role, id: $id, inp: $inp)
  }
`;
const CHECK_ID = gql`
  mutation ($role: String!, $id: String!) {
    result: checkDuplicateId(role: $role, id: $id)
  }
`;
