import { gql, useQuery } from '@apollo/client';
import React, { useState, useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto'; // Import Chart.js directly to access its functions
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import glitchImg from "../Assets/glitch.jpeg"
export default function StandInsights({ eventId, standId }) {
    const { data: getInsights, loading: insightsLoading, error: insightsError } = useQuery(GET_STANDS_INSIGHT, {
        variables: {
            eventId,
            standId
        }
    });

    console.log("insights",getInsights);

    if (insightsLoading) return <LinearProgress variant='query' color='secondary' />
    if (insightsError) return (
        <Box sx={{ width: "100%", minHeight: "55vh",padding:4, display: "flex", flexDirection:"column", justifyContent: "center", alignItems: "center" }}>
            <img
            src={glitchImg}
            alt="Hazard"
            style={{
              width: "290px",
              height: "250px",
              borderRadius: "7px",
              marginBottom: "16px"
            }}
          />
            <Typography textAlign={"center"} variant="h2" color="HighlightText">
                We have encountered a glitch.
            </Typography>
            <Typography textAlign={"center"} variant="h6" color="HighlightText">
                We kindly request you to try again later.
            </Typography>
        </Box>
    )

    return (
        <Grid container spacing={2}>
            <Grid item md={12}>
                <Typography variant='h3' my={2} textAlign={"center"}>Leadgen Insights</Typography>
            </Grid>
            <Grid item md={1}><Box flexGrow={1} /></Grid>
            <Grid item md={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} spacing={2}>
                <RegistrationInsights getInsights={getInsights} />
                <Typography textAlign={"center"} variant='h6' my={2} >Total Scan Insights</Typography>
            </Grid>
            <Grid item md={3}>
                <RoleInsights getInsights={getInsights} />
                <Typography textAlign={"center"} variant='h6' my={2} >Roles Scan Insights</Typography>
            </Grid>
            <Grid item md={3}><LeadTypeInsights getInsights={getInsights} /> <Typography textAlign={"center"} variant='h6' my={2} >Lead Type Scan Insights</Typography></Grid>
            <Grid item md={1}><Box flexGrow={1} /></Grid>
        </Grid>
    );
}


export function RegistrationInsights({ getInsights }) {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        if (getInsights && getInsights.result) {
            const insights = JSON.parse(getInsights.result);
            const allScans = insights.allScans;
            const staffData = insights.staffData;

            // Calculate scan counts for both staff and non-staff (admin)
            const scanCounts = {};
            allScans.forEach(scan => {
                scanCounts[scan.name] = (scanCounts[scan.name] || 0) + 1;
            });

            // Add staff scan counts if more than one staff member is found
            staffData.forEach(staff => {
                if (scanCounts[staff.name] > 1) {
                    scanCounts[staff.name] = scanCounts[staff.name] + 1;
                }
            });

            // Convert scan counts object into chart data
            const roles = Object.keys(scanCounts);
            const counts = Object.values(scanCounts);
            const chartData = {
                labels: roles,
                datasets: [
                    {
                        label: 'Scans per Role',
                        data: counts,
                        backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
                        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
                        borderWidth: 1,
                    },
                ],
            };

            setChartData(chartData);
        }
    }, [getInsights]);
    return (<>
        {chartData && <Doughnut data={chartData} options={{ responsive: true }} />}
    </>)
}
export function RoleInsights({ getInsights }) {
    const [chartData, setChartData] = useState(null);
    const chartRef = useRef(null); // Ref to access the Chart.js instance

    useEffect(() => {
        if (getInsights && getInsights.result) {
            const insights = JSON.parse(getInsights.result);
            console.log(">>>>>>>>>",insights);
            const allScans = insights.allScans;
            const scanCounts = {};

            allScans.forEach(scan => {
                const role = scan.role;
                scanCounts[role] = (scanCounts[role] || 0) + 1;
            });

            const roles = Object.keys(scanCounts);
            const counts = Object.values(scanCounts);

            const chartData = {
                labels: roles,
                datasets: [
                    {
                        label: 'Scans per Role',
                        data: counts,
                        backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
                        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
                        borderWidth: 1,
                    },
                ],
            };

            setChartData(chartData);
        }
    }, [getInsights]);

    return (<>
        {chartData && (<>
            <Doughnut data={chartData} options={{ responsive: true }} ref={chartRef} />
        </>
        )}
    </>)
}
export function LeadTypeInsights({ getInsights }) {
    const [chartData, setChartData] = useState(null);
    const chartRef = useRef(null); // Ref to access the Chart.js instance

    useEffect(() => {
        if (getInsights && getInsights.result) {
            const insights = JSON.parse(getInsights.result);
            console.log(">>>>>>>>>",insights);
            const allScans = insights.allScans;
            const scanCounts = {};

            allScans.forEach(scan => {
                const role = scan.type;
                scanCounts[role] = (scanCounts[role] || 0) + 1;
            });

            const roles = Object.keys(scanCounts);
            const counts = Object.values(scanCounts);

            const chartData = {
                labels: roles,
                datasets: [
                    {
                        label: 'Lead Type',
                        data: counts,
                        backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)', 'rgba(75, 192, 192, 0.5)'],
                        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
                        borderWidth: 1,
                    },
                ],
            };

            setChartData(chartData);
        }
    }, [getInsights]);

    return (<>
        {chartData && (<>
            <Doughnut data={chartData} options={{ responsive: true }} ref={chartRef} />
        </>
        )}
    </>)
}


const GET_STANDS_INSIGHT = gql`
  query ($eventId: String!, $standId: String!) {
    result: getStandsInsights(eventId: $eventId, standId: $standId)
  }
`;
