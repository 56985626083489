import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Backspace, Refresh } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Page from "./Page";

export const GET_ME = gql`
    query {
        me 
    }
`;

export default function MyVisitorsPage() {
  const [search, setSearch] = React.useState("");
  const { data, loading, error } = useQuery(GET_ME);

  const isAdmin = data && data?.me && data?.me === "admin" 
  // console.log(isAdmin);
 if(loading) return "Please wait..."
  return (
    <Page title="My Scanned Data">
      <Typography variant="h4" gutterBottom>
       {isAdmin? "Scanned Lead Data":"My Scanned Data"}
      </Typography>
{ error ? <Box sx={{width:"100%", minHeight:"55vh" , display:"flex", justifyContent:"center",alignItems:"center"}}>
  <Typography variant="h2" color="darkred">
  We have encountered a glitch. We kindly request you to try again later.
  </Typography>
   </Box> :
      <Stack spacing={2}>
        <SearchBox onSearch={setSearch} />

        <ApplicantsTable search={search} role="all" isAdmin={isAdmin} />

      </Stack>
}
      <Box p={6} />
    </Page>
  );
}

function SearchBox({ onSearch }) {
  const [search, setSearch] = React.useState("");
  React.useEffect(() => {
    const t = setTimeout(() => onSearch(search), 500);
    return () => clearTimeout(t);
  }, [search]);
  return (
    <TextField
      sx={{ maxWidth: 500 }}
      label="Search here..."
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={{
        autoComplete: "new-password",
        endAdornment: (
          <InputAdornment position="end">
            {search && (
              <IconButton onClick={() => setSearch("")}>
                <Backspace />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}

// function CustomFooter({ refetch }) {
//   const apiRef = useGridApiContext();
//   const page = useGridSelector(apiRef, gridPageSelector);
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         p: 1,
//         display: "flex",
//         justifyContent: "space-between",
//       }}
//     >
      
//       <Button onClick={() => refetch()} sx={{color:"white"}} variant="outlined" startIcon={<Refresh  size="large" />}>
//       Refresh
//       </Button>
     
//       <Pagination
//         color="primary"
//         count={pageCount}
//         page={page + 1}
//         onChange={(event, value) => apiRef.current.setPage(value - 1)}
        
//       />
//     </Box>
//   );
// }
// function CustomFooter({ refetch }) {
//   const apiRef = useGridApiContext();
//   const page = useGridSelector(apiRef, gridPageSelector);
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//   return (
//     <Box
//       sx={{
//         width: "100%",
//         p: 1,
//         display: "flex",
//         justifyContent: "space-between",
//       }}
//     >
//       <Button 
//         onClick={() => refetch()} 
//         sx={{ color: "white" }} 
//         variant="outlined" 
//         startIcon={<Refresh size="large" />}
//       >
//         Refresh
//       </Button>
     
//       <Pagination
//         color="primary"
//         count={pageCount}
//         page={page + 1}
//         onChange={(event, value) => apiRef.current.setPage(value - 1)}
//         showFirstButton
//         showLastButton
//       />
//     </Box>
//   );
// }

function ApplicantsTable({ search, role, isAdmin }) {
  const [page, setPage] = useState(0); // Track the current page
  const [pageSize, setPageSize] = useState(100); // Track the page size

  // Fetch data using the query with pagination variables
  const { data, error, refetch, loading } = useQuery(GET_DATA, {
    variables: {
      conditions: { text: search },
      role,
      offset: page * pageSize, // Calculating the offset for pagination
      limit: pageSize, // Limit the number of items per page
    },
    notifyOnNetworkStatusChange: true, // Notify on refetch (to show loading indicator)
  });

  // Handle dynamic columns based on the data structure
  let dynamicColumns = [];
  if (data?.applicants?.length) {
    const firstItem = data.applicants[0];
    const keys = Object.keys(JSON.parse(firstItem.data || "{}"));

    dynamicColumns = keys.map((k) => ({
      field: k,
      headerName: k
        .replace(/_/g, ' ')
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
      width: 150,
    }));

    // Add static columns at the beginning and end
    dynamicColumns.unshift(
      { field: 'serialNo', headerName: 'S.No', width: 50 },
      { field: 'regNo', headerName: 'Registration ID', width: 150 },
      { field: 'name', headerName: 'Lead Scanned By', width: 150 },
      { field: 'role', headerName: 'Role', width: 150 },
    );

    dynamicColumns.push(
      { field: 'type', headerName: 'Lead Type', width: 150 },
      { field: 'remark', headerName: 'Meeting Summary', width: 400 }
    );
  }

  const columns = [...dynamicColumns];

  const components = isAdmin ? { Toolbar: GridToolbar } : {};

  return (
    <Box>
      {error && <Typography color="error">An error occurred: {error.message}</Typography>}
      <DataGrid
        loading={loading}
        sx={{ height: 550 }}
        sortModel={[{ field: "created", sort: "desc" }]}
        components={components}
        columns={columns}
        rows={data?.applicants?.map((v, index) => ({
          serialNo: index + 1 + page * pageSize,  // Adjust serial number based on page
          id: `${v.id}-${index}`,
          regNo: v.id,
          key: `${v.id}-${index}`,
          created: v.created,
          role: v.role,
          name: v.name,
          type: v.type,
          remark: v.remark,
          ...JSON.parse(v.data || "{}"),  // Spread other dynamic data
        })) || []}
        // paginationMode="server" // Enable server-side pagination
        // pageSize={pageSize}
        rowCount={data?.total || 0} // Total number of rows from the server
        // page={page} // Controlled page state
        // onPageChange={(newPage) => {
        //   setPage(newPage);  // Update page state on page change
        //   refetch({
        //     conditions: { text: search },
        //     role,
        //     offset: newPage * pageSize,  // Adjust offset for new page
        //     limit: pageSize,  // Limit remains the same
        //   });
        // }}
        // onPageSizeChange={(newPageSize) => {
        //   setPageSize(newPageSize);  // Update page size
        //   setPage(0);  // Reset to the first page on page size change
        //   refetch({
        //     conditions: { text: search },
        //     role,
        //     offset: 0,  // Start from the first page
        //     limit: newPageSize,  // Fetch data with the new page size
        //   });
        // }}
        // rowsPerPageOptions={[25, 50, 100]} // Page size options
      />
    </Box>
      );
}


const GET_DATA = gql`
  query ($conditions: InputConditions, $role: String!) {
    applicants(conditions: $conditions, role: $role) {
      id
      data
      created
      name
      type
      remark
      role
    }
    total: applicantsCount(conditions: $conditions, role: $role)
  }
`;
