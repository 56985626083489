import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import AppLayout from "./Layouts/AppLayout";
import DashboardLayout from "./Layouts/DashboardLayout";
import LoginPage from "./Pages/LoginPage";
import LogoutPage from "./Pages/LogoutPage";
import MyVisitorsPage from "./Pages/MyVisitorsPage";
import ScanningVisitorsPage from "./Pages/ScanningVisitorsPage";
import TestPage from "./Pages/TestPage";
import AddStaffPage from "./Pages/AddStaffPage";
import InsightPage from "./Pages/InsightPage";

export default function IndexRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/:eventId/scanner" element={<ScanningVisitorsPage />} /> */}

        <Route path="/" element={<AppLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route path="scanner" element={<ScanningVisitorsPage />} />
            <Route path="staff" element={<AddStaffPage />} />
            <Route path="insight" element={<InsightPage />} />
            {/* <Route path="records" element={<StoragePage />} /> */}
            <Route path="test" element={<TestPage />} />
            <Route index element={<MyVisitorsPage />} />
          </Route>
          <Route index element={<Navigate to="dashboard" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  const [auth, setAuth] = React.useState(null);

  React.useEffect(() => {
    setAuth(!!localStorage.getItem("auth.exhibitor.eventegration.com"));
  }, []);

  if (auth === null) {
    return (
      <div>
        <p>Please wait...</p>
      </div>
    );
  } else if (auth === false) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
}
