import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Routes from "./Routes";
// import { createUploadLink } from "apollo-upload-client";
import { API_URL } from "./constants";
import { createTheme, ThemeProvider } from "@mui/material";

const httpLink = createHttpLink({
  uri: API_URL,
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth.exhibitor.eventegration.com");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// const uploadLink = createUploadLink({
//   uri: API_URL,
//   headers: {
//     "Apollo-Require-Preflight": "true",
//   },
// });

const client = new ApolloClient({
  //@ts-ignore
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const theme = createTheme({ palette: { mode: "dark" } });
function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <Routes />
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
